var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "标签",
        "ok-text": "确认",
        "cancel-text": "取消",
        width: 534
      },
      on: {
        cancel: function($event) {
          _vm.tagForms = {}
        },
        ok: _vm.addTag
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          {
            ref: "tagforms",
            attrs: { model: _vm.tagForms, rules: _vm.tagRules }
          },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "名称", prop: "coinTagName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "名称" },
                model: {
                  value: _vm.tagForms.coinTagName,
                  callback: function($$v) {
                    _vm.$set(_vm.tagForms, "coinTagName", $$v)
                  },
                  expression: "tagForms.coinTagName"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "标签分类", prop: "coinTagCategory" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "标签分类" },
                  model: {
                    value: _vm.tagForms.coinTagCategory,
                    callback: function($$v) {
                      _vm.$set(_vm.tagForms, "coinTagCategory", $$v)
                    },
                    expression: "tagForms.coinTagCategory"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("别名标签")
                  ]),
                  _c("a-select-option", { attrs: { value: 2 } }, [
                    _vm._v("钱币性质标签")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }