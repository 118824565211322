var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-upload",
    {
      key: _vm.imgKey,
      attrs: {
        action: _vm.ip + "/file/upload",
        "list-type": "picture-card",
        "file-list": _vm.list
      },
      on: {
        preview: function($event) {
          return _vm.handlePreview()
        },
        change: _vm.handleChange
      }
    },
    [
      _vm.list.length < _vm.maxCount
        ? _c(
            "div",
            [
              _c("a-icon", { attrs: { type: "plus" } }),
              _c("div", { staticClass: "ant-upload-text" }, [
                _vm._v("上传图片")
              ])
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }