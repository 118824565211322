<template>
  <a-modal
      v-model="showPopup"
      title="增加版别特征"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-item label="版别特征名称">
          <a-input
              allowClear
              v-model="params.natureName"
              placeholder='版别特征名称'
          ></a-input>
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {postAddVersionTagApi} from "@/views/cmsPage/currencyMannage/_apis"

export default {
  data() {
    return {
      showPopup: false,
      layout: {
        labelCol: {span: 6}, //标签占5份  共24份
        wrapperCol: {span: 18},
      },
      params: {
        coinId: undefined,
        natureName: undefined,
      }
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.params.coinId = data
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.params.natureName = undefined;
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.natureName) {
        return this.$message.warn('版别特征名称不能为空')
      }
      this.$loading.show()
      const res = await postAddVersionTagApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('change')
      this.handleCancel()
    }
  }
}
</script>