<template>
  <a-modal
      v-model="pushModal"
      :width='600'
      title="操作"
  >
    <template #footer>
      <a-button key="back" @click="cancelPushModal">取消</a-button>
      <a-button key="submit" type="primary" @click="confirmPushModal">确定</a-button>
    </template>
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="handleChangeHQ"
            @search="handleHQSearch"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 360px"
            v-model="curItem.coinKindVersionName"
            placeholder="请选择大币种"
        >
          <a-select-option
              v-for="item of selectList"
              :key="item.quanhaiVersionId"
              :value="item.quanhaiVersionId"
          >{{ item.versionName }}&nbsp;{{ item.aliasName ? `[${item.aliasName}]` : '' }}</a-select-option>
        </a-select>
      </div>
    </div>
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      pushModal: false,
      curItem: {},
      coinName: '',
      selectList: []
    };
  },
  computed: {
  },
  watch: {
    curItem: {
      handler: function (newVal, oldVal) {
        if (newVal.coinKindVersionName == undefined) {
          this.handleHQSearch(' ')
        }
      },
      deep: true
    },
    pushModal() {
      if (!this.pushModal) {
        this.curItem = {};
      }
    },
  },
  methods: {
    async show(data, versionName) {
      this.pushModal = true;
      this.curItem = JSON.parse(JSON.stringify(data))
      this.coinName = this.curItem.kindName
      this.curItem.quanhaiVersionId = this.curItem.quanhaiVersionId ? this.curItem.quanhaiVersionId.toString() : ''
      await this.handleHQSearch(versionName)
    },
    /** 改变选择 */
    handleChangeHQ(e) {
      this.curItem.quanhaiVersionId = e
    },
    /** 搜索 */
    async handleHQSearch(str) {
      const res = await this.axios("/dq_admin/qh/listVersionData", {
        params: {
          coinName: this.coinName,
          versionName: str
        },
      });
      if (res.status !== '200') return
      this.selectList = res.data
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal() {
      if (!this.curItem.quanhaiVersionId) {
        return this.$message.warn('请选择绑定数据')
      }
      this.$loading.show()
      const res = await this.axios.post("/dq_admin/qh/ownerVersionBindQuanhaiVersionId", {
        ownerVersionId: this.curItem.id,
        quanhaiVersionId: this.curItem.quanhaiVersionId,
        bindType: 0
      })
      this.$loading.hide()
      if (res.status != 200) return;
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>