import instance from "@/axios"

/** 获取自定义朝代列表 */
export const getCustomDynastyListApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanSupportCustomDynasty/list',
        method: 'GET',
        params: params
    })
}

/** 编辑自定义版别 */
export const postCustomDynastyApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanSupportCustomDynasty/add',
        method: 'POST',
        data: params
    })
}

/** 获取版别特征列表 */
export const getVersionNatureListApi = async (coinId) => {
    return instance({
        url: '/dq_admin/coinVersionNatureType/list',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 100,
            coinId: coinId,
        }
    })
}
/** 新增版别特征 */
export const postAddVersionTagApi = async (params) => {
    return instance({
        url: '/dq_admin/coinVersionNatureType/add',
        method: 'POST',
        data: params
    })
}

/** 获取版别相关 */
export const getVersionListApi = async (params) => {
    return instance({
        url: '/dq_admin/kinditemversion/list2',
        method: 'get',
        params: {
            pageNum: 1,
            pageSize: 200,
            ...params
        }
    })
}