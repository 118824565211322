<template>
  <a-modal
      v-model="show"
      title="标签"
      ok-text="确认"
      cancel-text="取消"
      @cancel="tagForms = {}"
      @ok="addTag"
      :width="534"
    >
      <a-form-model
        ref="tagforms"
        :model="tagForms"
        :rules="tagRules"
        v-bind="layout"
      >
        <a-form-model-item
          label="名称"
          prop="coinTagName"
        >
          <a-input
            placeholder="名称"
            v-model="tagForms.coinTagName"
          />
        </a-form-model-item>

        <a-form-model-item
          label="标签分类"
          prop="coinTagCategory"
        >
          <a-select
            placeholder="标签分类"
            v-model="tagForms.coinTagCategory"
          >
            <a-select-option :value="1">别名标签</a-select-option>
            <a-select-option :value="2">钱币性质标签</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
</template>

<script>
export default {
  props: {
    form: {},
  },
  data(){
    return {
      show: false,
      tagForms: {},
      tagRules: {
        coinTagCategory: [
          { required: true, message: "please change", trigger: "change" },
        ],
        coinTagName: [
          { required: true, message: "please change", trigger: "change" },
        ],
      },
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
    }
  },
  methods: {
    close(){
      this.show = false
    },
    open(){
      this.tagForms = JSON.parse(JSON.stringify({
        coinTagCategory: 1
      }))
      this.show = true
    },
    async addTag(){
      this.$emit("success", this.tagForms)
    },
  }
}
</script>

<style>

</style>