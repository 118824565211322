<template>
  <div class="content">
    <div class="search-attr" style="width: 100%;display: flex;justify-content: space-between;align-items: center">
      <div style="display: flex;justify-content: flex-start;align-items: center;flex-wrap: wrap">
        <a-input
          allowClear
          v-model="params.orderNo"
          size='small'
          style="width:130px;margin: 5px;"
          placeholder='订单号'
        ></a-input>
        <a-input
          allowClear
          v-model="params.expressNo"
          size='small'
          style="width:130px;margin: 5px;"
          placeholder='物流单号'
        ></a-input>
        <a-input
          allowClear
          v-model="params.productName"
          size='small'
          style="width:120px;margin: 5px;"
          placeholder='商品编号/名称'
        ></a-input>

        <a-input
          allowClear
          v-model="params.buyerUserName"
          size='small'
          style="width:145px;margin:5px;"
          placeholder='买家/uId/昵称/店铺'
        ></a-input>
        <a-input
          allowClear
          v-model="params.sellerUserName"
          size='small'
          style="width:145px;margin:5px;"
          placeholder='卖家/uId/昵称/店铺'
        ></a-input>
        
        <a-select
          allowClear
          v-model="params.status"
          size='small'
          style="width:100px;margin:5px;"
          placeholder='订单状态'
        >
          <a-select-option
            v-for="(item,key) of orderState"
            :value='item.value'
            :key="key"
          >{{item.label}}</a-select-option>
        </a-select>
        <a-select
          allowClear
          v-model="params.refundStatus"
          size='small'
          style="width:100px;margin:5px;"
          placeholder='退款状态'
        >
          <a-select-option
            v-for="(item,key) of resetOrderState"
            :value='item.value'
            :key="key"
          >{{item.label}}</a-select-option>
        </a-select>
        <a-select
          allowClear
          v-model="params.orderKind"
          size='small'
          style="width:100px;margin:5px;"
          placeholder='订单类型'
        >
          <a-select-option :value='1'>一口价</a-select-option>
          <!-- <a-select-option :value='2'>盲拍</a-select-option> -->
          <a-select-option :value='0'>竞拍单</a-select-option>
        </a-select>
        <!-- <a-date-picker
          allowClear
          v-model="params.createTime"
          placeholder="下单时间"
          size='small'
          style="width:120px;margin:5px;"
        /> -->

        <a-range-picker
              :placeholder="['订单开始日期','订单结束日期']"
              size='small'
              style="width:200px;margin:5px;"
              @change="searchPickerChange"
          />

        <a-range-picker
              :placeholder="['支付开始日期','支付结束日期']"
              size='small'
              style="width:200px;margin:5px;"
              @change="searchPayPickerChange"
          />
        <a-select
            allowClear
            v-model="params.productReviewStatus"
            size='small'
            style="width:100px;margin:5px;"
            placeholder='商品状态'
        >
          <a-select-option
              v-for="(item,key) of auctionTypeText"
              :value='item.value'
              :key="key"
          >{{item.label}}</a-select-option>
        </a-select>
        <a-select
            allowClear
            v-model="params.serviceType"
            size='small'
            style="width:200px;margin:5px;"
            placeholder='评级类型'
        >
          <a-select-option
              v-for="(item,key) of serviceTypeList"
              :value='item.value'
              :key="key"
          >{{item.label}}</a-select-option>
        </a-select>
        <a-range-picker
            :placeholder="['截拍开始日期','截拍结束日期']"
            size='small'
            style="width:200px;margin:5px;"
            @change="searchEndAuctionPickerChange"
        />
        <a-checkbox class="ml-20" :checked='isSendProductTimeoutStatus' @change="handleChangeShowSendProductTimeoutStatus">即将发货超时</a-checkbox>
        <a-button
          @click="search"
          size='small'
          type="primary"
          class="ml-20"
        >搜索</a-button>
      </div>
      <div>
        <a-dropdown>
          <a-button size="small" type="primary">平台数据统计</a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a-button @click="downloadExcel" class="w-100" type="primary" size='small'>拍卖统计</a-button>
              </a-menu-item>
              <a-menu-item>
                <a-button @click="handleShowDqTradeExcel" class="w-100" type="primary" size='small'>平台成交总览</a-button>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
    <div class="table-content">
      <a-table
        @change="changePage"
        :pagination='pagination'
        :loading='loading'
        :columns="columns"
        :data-source="data"
        :rowKey="(record,n)=>n"
      >
        <div
            slot="itemProductNameSlot"
            slot-scope="row"
            @click="handleGoToAuctionList(row)"
            :class="'cursor-pointer'"
        >
          {{ row.productName }}
          <span
              :class="classType[row.productReviewStatus]"
              v-if="row.productReviewStatus"
          >【{{ textType[row.productReviewStatus] }}】</span>
        </div>
        <template slot="price">
          <div style="display: flex; align-items: center">
            <div style="width: 50px">价格</div>
            <div
              style="display: flex; flex-direction: column; align-items: center"
            >
              <a-icon
                :style="params.sort == 1 ? 'color:#1890ff' : ''"
                @click="sortPrice(1)"
                type="caret-up"
              />
              <a-icon
                :style="params.sort == -1 ? 'color:#1890ff' : ''"
                @click="sortPrice(-1)"
                type="caret-down"
              />
            </div>
          </div>
        </template>
        <div slot="itemRateServiceStatusSlot" slot-scope="row">
          <span :class="rateServiceStatusClassType[row.ratingStatus]">{{ computedRateServiceStatusMapTypeText(row.ratingStatus) || '未送评' }}</span>
        </div>
<!--    订单装填    -->
        <div slot="itemStatusNameSlot" slot-scope="row">
          <div>{{ row.statusName }}</div>
          <div v-if="computedUserShowSynchronousOrder(row)">
            <a-popconfirm
                title="确定要同步当前订单？？"
                ok-text="确认"
                cancel-text="取消"
                @confirm="handleSynchronousPay(row)"
            >
              <a-button size="small">同步支付订单</a-button>
            </a-popconfirm>
          </div>
          <div v-if="row.status === 0">
            <a-button
                type="danger"
                size="small"
                class="mt-10"
                @click="handleSynchronousUnderlinePay(row)"
            >线下支付同步</a-button>
          </div>
        </div>
        <div
          slot="setup"
          slot-scope="row"
        >
          <a @click="openModal(row)">查看详情</a>
          <a
            @click="openModalSnapshot(row.orderProductId)"
            style='margin-left:8px'
          >快照</a>
        </div>
      </a-table>
    </div>

    <a-modal
      v-model="show"
      :width='1200'
      centered
      :footer='null'
    >
      <div :style="'max-height:'+ModalHeight+'px;overflow:auto;'">
        <div class="modal-items">
          <div class="modal-content">
            <div class="title">基本信息</div>
            <div align="center" class="box-box" style="margin-left: 40px;display: flex;justify-content: flex-start;align-items: center">
              <a-button
                  v-if="![-10, -14].includes(orderProductInfoVo.refundStatus)"
                type='primary'
                @click="openRefundModal(orderProductInfoVo)"
                >发起退货退款申请
              </a-button>
              <a-popconfirm
                title="确认延长收货么?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="bidOrderDelay(orderProductInfoVo.id)"
              >
                <a-button
                    v-if="orderProductInfoVo.refundStatus == null || orderProductInfoVo.refundStatus == -12"
                  style="margin-left:10px;"
                  type='primary'
                >延长收货</a-button>
              </a-popconfirm>
              <a-tag
                  v-for="(tag, index) in tagDayList"
                  :key="index"
                  class="cursor-pointer ml-20"
                  :color="tag.selected ? 'green' : ''"
                  @click="handleChangeTag(tag.id)"
              >{{ tag.name }}</a-tag>
            </div>
          </div>
          
          <div class="modal-content">
            <div class="basic-item">订单编号：<span class="color-blue">{{orderProductInfoVo.orderNo}}</span></div>
            <div class="basic-item">买家：<span class="color-blue">{{orderProductInfoVo.buyerUserName}}</span></div>
            <div class="basic-item">卖家：<span class="color-blue">{{orderProductInfoVo.sellerUserName}}</span></div>
            <div class="basic-item">商户订单号：{{orderProductInfoVo.requestNo||''}}</div>
            <div class="basic-item">下单/截拍时间：{{orderProductInfoVo.createTime||''}}</div>
          </div>

          <div class="modal-content">
            <div class="basic-item">订单类型：<span style="color:red">{{ orderProductInfoVo.orderKind == 0 ? '竞拍单' : '一口价' }}</span></div>
            <div
                class="basic-item"
            >订单支付类型：
              <span :class="payTypeListClassType[orderProductInfoVo.payType]"
              >{{ computedPayTypeListMapTypeText(orderProductInfoVo.payType) }}</span>
            </div>
          </div>

          <div class="modal-content">
            <div class="basic-item">订单原价/中拍价：<span style="color:red">{{orderProductInfoVo.orderPrice}}元</span></div>
            <div class="basic-item">买家手续费：<span style="color:red">{{orderProductInfoVo.buyerServiceMoney }}元</span></div>
            <div class="basic-item">卖家手续费：<span style="color:red">{{orderProductInfoVo.sellerServiceMoney }}元</span></div>
            <div class="basic-item">发货运费：<span style="color:red">{{orderProductInfoVo.logisticsFee}}元</span></div>
            <div class="basic-item">冻结保证金：<span style="color:red">{{ orderProductInfoVo.deductDepositMoney || 0 }}元</span>（<span class="color-red">{{ orderProductInfoVo.depositReleaseDesc }}</span>）</div>
            <div class="basic-item">订单总金额：<span style="color:red"><span>{{ orderProductInfoVo.orderTotalPrice || 0 }}元</span></span></div>
          </div>
        </div>

<!--    评级信息    -->
        <div class="modal-items mt-20" v-if="ratingInfoVo">
          <div class="title">评级信息</div>
          <div class="modal-content">
            <div class="basic-item">评级费：<span style="color:red">{{ ratingInfoVo.ratingMoney || 0 }}元</span></div>
            <div class="basic-item">标版费：
              <span style="color:red"><span>{{ ratingInfoVo.biaobanMoney || 0 }}元</span><span v-if="ratingInfoVo.biaobanPayStatus === 6" class="color-gary">（已退回）</span></span>
              <a-popconfirm
                  title="确定退回标版费?"
                  ok-text="Yes"
                  cancel-text="No"
                  okType="danger"
                  @confirm="handleReturnVersionPrice(orderProductInfoVo.id)"
              >
                <a-button v-if="ratingInfoVo.biaobanPayStatus === 2" class="ml-20" size="small" type='danger'>退标版费</a-button>
              </a-popconfirm>
            </div>
          </div>
        </div>

        <div class="modal-items mt-20" v-if="orderProductInfoVo.payTime">
          <div class="title">支付信息</div>
          <div class="modal-content modal-express">
            <div class="basic-item">支付时间：
              <span class="color-red">{{orderProductInfoVo.payTime|| '未支付'}}</span>
              <span v-if="orderProductInfoVo.status == 0">（倒计时<span class="color-red">{{ 'xxxx' }}</span>）</span>
            </div>
            <div class="basic-item">货币支付：<span class="color-red">{{orderProductInfoVo.payMoney||''}}</span>元</div>
            <div class="basic-item">
              优惠支付：<span
                class="color-red"
                v-if="orderProductInfoVo.deductPaymentMoney"
            >{{orderProductInfoVo.deductPaymentMoney + '元'}}</span> <span class="color-red"  v-else>无</span>，
              优惠方式: <span class="color-red">{{orderProductInfoVo.deductPaymentDesc || '无'}}</span>
            </div>
            <div class="w-100-w" style="display: flex;justify-content: flex-start;align-items: center">
              <div v-if="[1, 2, 3, 4].includes(orderProductInfoVo.status)"  class="dis-flex-start">
                <a-popconfirm
                    title="确认主动退款么?"
                    ok-text="Yes"
                    cancel-text="No"
                    okType="danger"
                    @confirm="activeRefund(orderReturnVo.id, orderProductInfoVo.id)"
                >
                  <a-button
                      type='danger'
                  >主动退款</a-button>
                </a-popconfirm>
                <span class="color-red ml-20">（一键退款，本功能无需买家退货，谨慎操作）</span>
              </div>
              <div>
                <!--       订单赔付       -->
                <a-button
                    class="ml-20"
                    @click="handleShowOrderPayPopup(orderProductInfoVo.id)"
                >订单赔付</a-button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-items mt-20">
          <div class="title">订单状态</div>
          <div class="modal-content modal-express">
            <div class="dis-flex-start-start">
              <div class="left-content dis-flex-start">
                <div class="basic-item ml-20">订单主状态：
                  <span class="color-red">{{orderProductInfoVo.statusName}}</span>
                  <span
                      v-if="orderProductInfoVo.offlineStatus"
                      class="ml-20"
                  >（<span class="color-red">线下交易</span>）</span>
                  <span
                      v-if="orderProductInfoVo.orderRemainTime"
                  >
                    （<span class="color-red">{{orderProductInfoVo.orderRemainTime}}</span>）
                  </span>
                </div>
                <div class="basic-item">
                  送评类型：
                  <a-tooltip v-if="orderProductInfoVo.orderRatingServiceRank">
                    <template slot="title">
                      {{ computedRateServicePayTypeTextByDesc(orderProductInfoVo.orderRatingServiceRank) }}---{{ computedRateServiceStatusMapTypeText(orderProductInfoVo.ratingStatus) || '未发货' }}
                    </template>
                    <span
                        class="fs-20"
                        :class="dqCheckPayClassType[orderProductInfoVo.orderRatingServiceRank]"
                    >{{ computedRateServicePayTypeText(orderProductInfoVo.orderRatingServiceRank) }}</span>
                    <span class="color-blue">---</span>
                    <span
                        :class="rateServiceStatusClassType[orderProductInfoVo.ratingStatus]"
                    >{{ computedRateServiceStatusMapTypeText(orderProductInfoVo.ratingStatus) || '未发货' }}</span>
                  </a-tooltip>
                  <span v-else>未送评</span>
                  <a-popconfirm
                      title="确定要取消评级转为直发？"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="handleConfirmCancelRateService(orderProductInfoVo.id)"
                  >
                    <a-button
                        class="ml-20"
                        v-if="orderProductInfoVo.orderRatingServiceRank"
                        size="small"
                        type="primary"
                    >取消评级转直发</a-button>
                  </a-popconfirm>
                </div>
              </div>
              <div class="right-content ml-200">
                <div class="basic-item" v-if="orderProductInfoVo.refundStatusName">
                  <span>逆向子状态：<span class="color-red">{{orderProductInfoVo.refundStatusName}}</span></span>
                  <span v-if="orderProductInfoVo.orderReturnRemainTime">（{{orderProductInfoVo.orderReturnRemainTime}}）</span>
                </div>
                <div v-if="orderReturnVo.id">
                  <a-button @click="handleGoToReturnOrder(orderProductInfoVo.orderNo)">查看详情</a-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-items mt-20">
          <div class="title">商品信息</div>
          <div class="modal-content modal-express" v-if="productSnapshot">
            <div class="dis-flex-start-start">
              <div class="left-content">
                <img
                    style="margin-right: 20px;width: 30px;"
                    mode="widthFix"
                    v-for="(item, index) in productSnapshot.files"
                    :src="item"
                    :key="index"
                    alt=""
                    @click="handleShowBigImg(index)"
                />
                <div class="basic-item mt-20">尺寸：{{ productSnapshot.productSize }}</div>
                <div class="basic-item">评级公司：{{ productSnapshot.ratingCompanyName || '无' }}</div>
                <div class="basic-item">评级编号：{{ productSnapshot.productCode || '无'}}</div>
                <div class="basic-item" style="color: orange">
                  承诺服务：
                  <span
                      v-for="(item, index) in productSnapshot.serviceList"
                      :key="index"
                  >{{ item.title }}/</span>
                </div>
              </div>
              <div class="right-content ml-20">
                <div>商品名称：{{ productSnapshot.productName }}</div>
                <div class="mt-20 color-red">瑕疵：{{ productSnapshot.productRemark }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="dis-flex-start-start mt-20">
          <div class="modal-items">
            <div class="title">
              <span>收货地址与物流</span>
              <a-button
                  v-if="orderLogisticsVo.consigneeRealname"
                  class="change-btn"
                  size="small"
                  type="danger"
                  @click="handleChangeLogisticsMessage(orderLogisticsVo, orderID, 'order')"
              >修改发货物流</a-button>
            </div>
            <div class="modal-content modal-express">
              <div class="express">
                <span>收件人：{{ orderLogisticsVo.consigneeRealname }}</span>
                <span style="margin-left: 40px">收件人电话：{{ orderLogisticsVo.consigneeTelphone }}</span>
              </div>
              <div class="express">详细地址：{{orderLogisticsVo.consigneeAddress}}</div>
              <div class="express">发货物流：{{orderLogisticsVo.logisticsName}}</div>
              <div
                  class="express"
                  @click="untils.copy_text(orderLogisticsVo.expressNo)"
              >物流单号：{{orderLogisticsVo.expressNo}}</div>
            </div>
          </div>

          <div class="modal-items">
            <div class="title">
              <span>退货地址与物流</span>
              <a-button
                  v-if="orderReturnVo.expressNo"
                  class="change-btn"
                  size="small"
                  type="danger"
                  @click="handleChangeLogisticsMessage(orderReturnVo, orderReturnVo.id, 'returnOrder')"
              >修改退货物流</a-button>
            </div>
            <div class="modal-content modal-express">
              <div class="express">
                <span>收件人：{{ orderReturnVo.consigneeRealname }}</span>
                <span style="margin-left: 40px">收件人电话：{{ orderReturnVo.consigneeTelphone }}</span>
              </div>
              <div class="express">详细地址：{{orderReturnVo.consigneeAddress}}</div>
              <div class="express">发货物流：{{orderReturnVo.logisticsName}}</div>
              <div
                  class="express"
                  @click="untils.copy_text(orderReturnVo.expressNo)"
              >物流单号：{{orderReturnVo.expressNo}}</div>
            </div>
          </div>

          <div class="modal-items" v-if="gradeLogisticsVo">
            <div class="title">
              <span>送评物流</span>
              <a-button
                  class="change-btn"
                  size="small"
                  type="danger"
                  @click="handleChangeLogisticsMessage(gradeLogisticsVo, orderID, 'rateService')"
              >修改送评物流</a-button>
            </div>
            <div class="modal-content modal-express">
              <div class="express">
                <span>收件人：{{ gradeLogisticsVo.consigneeRealname }}</span>
                <span style="margin-left: 40px">收件人电话：{{ gradeLogisticsVo.consigneeTelphone }}</span>
              </div>
              <div class="express">详细地址：{{gradeLogisticsVo.consigneeAddress}}</div>
              <div class="express">物流公司：{{gradeLogisticsVo.logisticsName || ''}}</div>
              <div
                  class="express"
                  @click="untils.copy_text(gradeLogisticsVo.expressNo || '')"
              >物流单号：{{gradeLogisticsVo.expressNo || ''}}</div>
              <div class="express">评级公司：{{gradeLogisticsVo.gradeCompanyName || ''}}</div>
            </div>
          </div>
        </div>

        <div class="modal-items">
          <div class="title">备注/操作</div>
          <div class="modal-content">
            <a-textarea
              v-model="remarkText"
              style="width:400px;margin-bottom:20px;margin-right:20px;"
              placeholder="请输入备注"
              auto-size
            />
            <a-button
              type='primary'
              @click="remark(orderID)"
            >备注</a-button>
          </div>
        </div>

        <div class="modal-items">
          <div class="title">订单日志</div>
          <div class="modal-content modal-express">
            <div
              class="order-log"
              v-for="(item,index) of orderLogVo"
              :key="index"
            >
              <div>{{item.createTime}}</div>
              <div>{{item.userName}}</div>
              <div>{{item.type == 1 ? '系统提示' : (item.type == 2 ? '后台操作' : '备注')}}：{{item.content||''}}</div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="snapshotshow"
      :width='500'
      centered
      :footer='null'
    >
      <div :style="'max-height:'+ModalHeight+'px;overflow:auto;'">
        <div class="modal-items">
          <div class="title">快照信息</div>

          <div class="modal-content modal-express">

            <div class="basic-item">商品编号：{{productVersion.productNo}}</div>
            <div class="basic-item">商品名称：{{productVersion.productName||''}}</div>
            <div class="basic-item">商品价格：<span style="color:red">￥{{productVersion.finalPrice}}</span></div>
            <div class="basic-item">运费：<span style="color:red">￥{{productVersion.logisticsFee}}</span></div>
            <div class="express" v-if="productVersion">
              <span>图片：</span>
              <img
                @click="previewImg(productVersion.files)"
                v-for='(item,key) of productVersion.files'
                :key="key"
                style="max-width:50px;max-height:50px;margin-right:5px;"
                :src='item'
              >
            </div>
            <div class="basic-item">
              商品服务：
              <a-tag
                v-for="(tag, index) in productVersion.serviceList"
                :key="index"
                color="blue"
              >
                {{tag.title}}
              </a-tag>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal
      centered
      :width='900'
      v-model="showRefundModal"
      @ok='checkForms'
      @cancel='cancelRefund'
    >
      <div class="modal-items">
        <div class="title">退货退款</div>
        <a-form-model
          v-bind="layout"
          :model='refundForms'
          :rules='refundRules'
          ref="ruleForm"
          :label-col="{ span: 5 }"
          :wrapperCol="{ span: 15 }"
        >
          
          <a-form-model-item
            label='申请退货退款'
            prop='section'
          >
            <a-select
              
              v-model="refundForms.refundType"
              :filter-option="untils.filterOption"
              placeholder='选择退货原因'
              @change="handleChangeRefundType"
            >
              <a-select-option value='buyernoreason'>买家无理由[买家责任]-[扣买家]</a-select-option>
              <a-select-option value='productflaw'>商品瑕疵[卖家责任]-[扣卖家]</a-select-option>
              <a-select-option value='sham'>假货[卖家责任]-[扣卖家]</a-select-option>
              <a-select-option value='sellernoreason'>图片与实物不符[卖家责任]-[扣卖家]</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
              :label='depositTitle'
          >
            <a-radio-group name="radioGroup" :default-value="0" @change="handleChangeRadio">
              <a-radio :value="1">扣除</a-radio>
              <a-radio :value="0">不扣除</a-radio>
            </a-radio-group>
          </a-form-model-item>

<!--    是否扣除手续费      -->
          <a-form-model-item
              label='是否扣除手续费'
          >
            <a-radio-group name="radioGroup" :default-value="1" @change="handleChangeRadioByHandleFees">
              <a-radio :value="1">扣除</a-radio>
              <a-radio :value="0">不扣除</a-radio>
            </a-radio-group>
          </a-form-model-item>

<!--    修改退款类型      -->
          <a-form-model-item
              label='退款类型'
          >
            <a-radio-group name="radioGroup" :default-value="-1" @change="handleChangeRadioReturnAndRefundsType">
              <a-radio value="goodsnotreceived">仅退款</a-radio>
              <a-radio value="goodsreceived">退货退款</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item :label='logisticsFeeTitle'>
            <a-input type="number"
              :placeholder='logisticsFeeTitle'
              v-model="refundForms.expressMoney"
              @change="handleChangeRefundType"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item :label='extraFeeTitle'>
            <a-input type="number"
              :placeholder='extraFeeTitle'
              v-model="refundForms.certificateMoney"
              @change="handleChangeRefundType"
            ></a-input>
          </a-form-model-item>

         <div v-if="userRateData">
           <div style="color: red;">{{ userRateData.couponDesc }}</div>
           <div style="color: red;">{{ userRateData.depositDesc }}</div>
           <div style="color: red;">{{ userRateData.serviceMoneyDesc }}</div>
           <div style="color: green;">{{ userRateData.incomePersonStr }}</div>
         </div>

          <a-form-model-item label='补充说明'>
            <a-input
              type='textarea'
              placeholder='补充说明'
              v-model="refundForms.refundReason"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item
            label='附件图片'
          >
            <a-upload
                :key="imgKey"
                :action="ip + '/file/upload'"
                list-type="picture-card"
                :file-list="picList"
                @preview="handlePreview()"
                @change="handleChange"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
          </a-form-model-item>

        </a-form-model>
      </div>
    </a-modal>
    <ChangeLogisticsMessage ref="logisticsMessagePopup" @change="getDetail($event)"/>
<!--  赔偿  -->
    <OrderPaidPopup ref="orderPaidPopupEl" @paidOrderPrice="getDetail(orderID)"/>
<!--  斗泉交易平台总览  -->
    <DqTradeDownloadExcelPopup ref="dqTradeDownloadExcelPopupEl"/>
  </div>
</template>



<script>
import {mapState} from "vuex";
import ChangeLogisticsMessage from "@/views/cmsPage/dqPlatform/components/ChangeLogisticsMessage";
import {newExportsExcel} from "@/untils/downLoad";
import {
  computedRateServiceStatusMapType,
  dqCheckPayClassType,
  payTypeListClassType,
  payTypeListMapType,
  rateServicePayTypeMapText,
  rateServicePayTypeMapTextByDesc,
  rateServiceStatusClassType,
  tagDayList
} from "@/views/cmsPage/dqPlatform/_data"
import OrderPaidPopup from "@/views/cmsPage/dqPlatform/components/OrderPaidPopup"
import {getLocalEnv} from "@/untils/otherEvent"
import DqTradeDownloadExcelPopup from "@/views/cmsPage/dqPlatform/components/DqTradeDownloadExcelPopup/index.vue"

export default {
  components: {ChangeLogisticsMessage, OrderPaidPopup, DqTradeDownloadExcelPopup},
  data() {
    return {
      payTypeListClassType,
      tagDayList: [],
      classType: {
        1: 'color-blue',
        2: 'color-black',
        3: 'color-gary',
        8: 'color-orange'
      },
      textType: {
        1: '精品',
        2: '普品',
        3: '差品',
        8: '臻品'
      },
      productSnapshot: '',
      refundTypeText: {
        buyernoreason: '买家无理由[买家责任]-[扣买家]',
        productflaw: '商品瑕疵[卖家责任]-[扣卖家]',
        sham: '假货[卖家责任]-[扣卖家]',
        sellernoreason: '卖家无理由[卖家责任]-[扣卖家]',
        logisticsIssues: '物流问题[无责]'
      },
      refundRules: {
        refundType: [{required: true, message: '必须选择类型', trigger: 'blur'}],
      },
      isDeductDeposit: 0,
      returnAndRefundsType: null,
      isDeductService: 1,
      picList: [],
      rateServiceStatusClassType,
      columns: [
        { title: "订单号", dataIndex: "orderNo" },
        {
          title: "订单类型",
          dataIndex: "orderKind",
          customRender: (text) => {
            const inner = text;
            if (inner == 0) {
              return <span style="color:green">竞拍单</span>;
            }
            if (inner == 1) {
              return <span style="color:red">一口价</span>;
            }
          },
        },
        {
          title: "支付方式",
          dataIndex: "payType",
          customRender: (text) => {
            const inner = text;
            if (inner == 1) {
              return <span style="color:green">小程序</span>;
            }
            if (inner == 2) {
              return <span style="color:red">APP</span>;
            }
          },
        },

        { title: "商品", scopedSlots: { customRender: 'itemProductNameSlot'}, width: 220 },
        {
          title: "商品评级类型",
          dataIndex: "serviceType",
          width: 200,
          customRender: (text) => {
            const inner = text;
            if (inner === 1) {
              return <span style="color:green">放心购-选择评级</span>;
            }
            if (inner === 2) {
              return <span style="color:red">放心购-放弃评级</span>;
            }
            if (inner === 3) {
              return <span style="color: blue">没有放心购-选择评级</span>
            } else {
              return <span style="color: gray">其他</span>
            }
          },
        },
        { title: '评级状态', scopedSlots: {customRender: 'itemRateServiceStatusSlot'}, width: 150 },
        { title: "买家UID", dataIndex: "buyerUserId" },
        { title: "买家", dataIndex: "buyerUserName" },
        { title: "卖家UID", align: "center", dataIndex: "sellerUserId" },
        { title: "卖家", align: "center", dataIndex: "sellerUserName" },
        // { title: "价格", align: "center", dataIndex: "finalPrice" },
        {
          key: "action",
          scopedSlots: { customRender: "action", title: "price" },
          customRender: (text, row, index) => {
            return <span style="color:red">{row.orderPrice}</span>;
          },
        },
        { title: "订单状态", align: "center", scopedSlots: { customRender: 'itemStatusNameSlot'}},
        { title: "退款状态", align: "center", dataIndex: "refundStatusName" },
        { title: "下单时间", dataIndex: "createTime" },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      // createTime:'',
      // payTime:'',
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      visible: false,
      params: {
        pageNum: 1,
        pageSize: 10,
        buyerUserName: "",
        sellerUserName: "",
        productName: "",
        orderNo: "",
        sendStatus: 0
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
        showSizeChanger: false,
        pageSizeOptions: ['10', '50', '100', '150', '200'],
      },
      orderState: [
        { label: "非取消", value: -2 },
        { label: "已取消", value: -1 },
        { label: "待付款", value: 0 },
        { label: "待发货", value: 1 },
        { label: "待收货", value: 3 },
        { label: "延长收货", value: 4 },
        { label: "待评价", value: 5 },
        { label: "已完成", value: 7 },
      ],
      resetOrderState: [
        { label: "退款中", value: -10 },
        { label: "退款取消", value: -12 },
        { label: "退款完成", value: -14 },
      ],
      auctionTypeText: [
        { label: "臻品", value: 8 },
        { label: "精品", value: 1 },
        { label: "普品", value: 2 },
        { label: "差品", value: 3 },
      ],
      serviceTypeList: [
        { label: '放心购-选择评级', value: 1 },
        { label: '放心购-放弃评级', value: 2 },
        { label: '没有放心购-选择评级', value: 3 },
      ],
      loading: false,
      show: false,
      snapshotshow: false,
      ratingInfoVo: {},
      orderProductInfoVo: {},
      orderLogisticsVo: {},
      orderLogVo: [],
      orderReturnVo: {},
      gradeLogisticsVo: '',
      buyerDetailList: {},
      sellerDetailList: {},
      ModalHeight: null,
      remarkText: "",
      orderID: null,
      orderId: null,
      productVersion: {},
      showRefundModal: false,
      refundForms: {},
      userRateData: {},
      currentLogType: '',
      dqCheckPayClassType,
      returnPayLoading: false,
      isSendProductTimeoutStatus: false
    }
  },
  created() {
    const evn = getLocalEnv()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (['local', 'dev'].includes(evn)) {
      this.pagination.showSizeChanger = true
    } else if ([4].includes(userInfo.id)) {
      this.pagination.showSizeChanger = true
    }
    const { orderNo } = this.$route.query;
    this.$set(this.params, "orderNo", orderNo);
    this.getData(this.params);
    const { innerHeight } = window;
    this.ModalHeight = innerHeight - 100;
  },
  computed: {
    ...mapState(['ip']),
    imgKey() {
      return Math.random();
    },
    computedUserShowSynchronousOrder() {
      const evn = getLocalEnv()
      console.log(evn, '环境')
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      return row =>  {
        if (row.status === 0) {
          if (['local', 'dev'].includes(evn)) {
            return true
          } else return evn === 'production' && [11, 39].includes(userInfo.id);
        } else {
          return false
        }
      }
    },
    computedPayTypeListMapTypeText() {
      return value => payTypeListMapType(value)
    },
    computedRateServiceStatusMapTypeText() {
      return value => computedRateServiceStatusMapType(value)
    },
    computedRateServicePayTypeText() {
      return (value) => rateServicePayTypeMapText(value)
    },
    computedRateServicePayTypeTextByDesc() {
      return (value) => rateServicePayTypeMapTextByDesc(value)
    },
    showExpress() {
      const { refundStatus = null } = this.orderProductInfoVo;
      return refundStatus != null;
    },
    showSet() {
      const { refundStatus = null } = this.orderProductInfoVo;
      return refundStatus === -10;
    },
    logisticsFeeTitle(){
      const { refundType = null } = this.refundForms;
      if(refundType === 'buyernoreason'){
        return "买家承担卖家发货运费金额";
      }
      if(refundType === 'productflaw'){
        return "卖家承担买家退货运费金额";
      }
      if(refundType === 'sham'){
        return "卖家承担买家退货运费金额";
      }
      if(refundType === 'sellernoreason'){
        return "卖家承担买家退货运费金额";
      }
      return "运费";
    },
    extraFeeTitle(){
      const { refundType = null } = this.refundForms;
      if(refundType === 'buyernoreason'){
        return "买家承担卖家额外费用";
      }
      if(refundType === 'productflaw'){
        return "卖家承担买家送评额外费用";
      }
      if(refundType === 'sham'){
        return "卖家承担买家送评额外费用";
      }
      if(refundType === 'sellernoreason'){
        return "卖家承担买家额外费用";
      }
      return "额外费用";
    },
    depositTitle(){
      const { refundType = null } = this.refundForms;
      if(refundType === 'buyernoreason'){
        return "是否扣除买家保证金";
      }
      if(refundType === 'productflaw' || refundType === 'sham' || refundType === 'sellernoreason'){
        return "是否扣除卖家保证金";
      }
      return "是否扣除保证金";
    },
  },
  methods: {
    /** 退标版费 */
    async handleReturnVersionPrice(id) {
      this.$loading.show()
      const res = await this.axios("/dq_admin/orderProduct/returnBiaobanMoney", {
        params: {
          orderId: id
        }
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('退款成功')
      await this.getDetail(id);
    },
    /** 平台交易总览弹窗 */
    handleShowDqTradeExcel() {
      this.$refs.dqTradeDownloadExcelPopupEl.show()
    },
    /** 改变选择是否发货即将超时 */
    async handleChangeShowSendProductTimeoutStatus(e) {
      this.isSendProductTimeoutStatus = e.target.checked
      if (this.isSendProductTimeoutStatus) {
        this.params.sendStatus = 1
      } else {
        this.params.sendStatus = 0
      }
      await this.getData(this.params)
    },
    /** 线下支付同步 */
    async handleSynchronousUnderlinePay(row) {
      this.$confirm({
        title: '提示！',
        content: '次功能只支持直发订单同步,确认好页面显示的支付金额,金额不对联系技术,评级单不支持要联系技术处理',
        onOk: async () => {
          const res = await this.axios("/dq_admin/orderProduct/offinePaySynchronizationPayStatus", {
            params: {
              orderNo: row.orderNo
            }
          })
          if (res.status !== '200') return
          await this.getData(this.params)
        },
        onCancel() {},
      });
    },
    /** 同步订单支付 */
    async handleSynchronousPay(row) {
      const res = await this.axios("/dq_admin/orderProduct/synchronizationPayStatus", {
        params: {
          orderNo: row.orderNo
        }
      })
      if (res.status !== '200') return
      await this.getData(this.params)
    },
    /** 显示 */
    handleShowOrderPayPopup(id) {
      this.$refs.orderPaidPopupEl.show(id)
    },
    /** 取消评级直接发 */
    async handleConfirmCancelRateService(id) {
      this.$loading.show()
      const res = await this.axios("/dq_admin/orderProduct/cancelRatingToDirectMode", {
        params: {
          orderId: id
        },
      });
      this.$loading.hide()
      if (res.status !== '200') return;
      await this.getDetail(this.orderID)
    },
    /** 去往拍品列表页 */
    handleGoToAuctionList(item) {
      const origin = location.origin
      let url
      if (item.orderKind === 1) {
        url = `${origin}/#/cms/currencymannage/goods?productName=${item.productId}`
      } else {
        url = `${origin}/#/cms/currencymannage/auctiongoods?productName=${item.productId}`
      }
      window.open(url)
    },
    /** 下载文档 */
    async downloadExcel() {
      newExportsExcel('/dq_admin/product/bidStatistics', '拍品统计')
    },
    /** 修改用户物流 */
    handleChangeLogisticsMessage(data, orderNo, type) {
      this.currentLogType = type
      this.$refs.logisticsMessagePopup.show(data, orderNo, type)
    },
    /** 去往退款订单页面 */
    handleGoToReturnOrder(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/orderreturnmannage?orderNo=${orderNo}`;
      window.open(url)
    } ,
    /** 查看大图 */
    handleShowBigImg(index) {
      const list = this.productSnapshot.files.map((el) => {
        return {img_url: el};
      });
      this.$previewImg({
        current: index,
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    /** 改变退款原因 */
    async handleChangeRefundType(e) {
      await this.getBuyerAndSellerRate()
    },
    /** 获取买卖双方费率 */
    async getBuyerAndSellerRate() {
      const postData = {
        orderId: this.refundForms.orderId,
        refundType: this.refundForms.refundType,
        isDeductDeposit: this.isDeductDeposit === null ? null : this.isDeductDeposit,
        expressMoney: this.refundForms.expressMoney * 1,
        isDeductService: this.isDeductService,
        certificateMoney: this.refundForms.certificateMoney * 1
      }
      if (this.isDeductDeposit === null) {
        delete postData.isDeductDeposit
      }
      if (this.isDeductService === null) {
        delete postData.isDeductService
      }
      const res = await this.axios.post(
          "/dq_admin/orderReturns/calculateChangeRefundType",
          postData
      );
      if (res.status == "200") {
        this.userRateData = res.data
      }
    },
    /** 修改退款类型 */
    async handleChangeRadioReturnAndRefundsType(e) {
      this.returnAndRefundsType = e.target.value
      await this.getBuyerAndSellerRate()
    },
    /** 是否扣除保证金 */
    async handleChangeRadio(e) {
      this.isDeductDeposit = e.target.value
      await this.getBuyerAndSellerRate()
    },
    /** 是否扣除手续费 */
    async handleChangeRadioByHandleFees(e) {
      this.isDeductService = e.target.value
      await this.getBuyerAndSellerRate()
    },
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.picList = fileList;
    },
    async handlePreview() {
      let urls = this.picList.map((el) => {
        return {
          img_url: el.url,
        };
      });
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    checkForms() {
      if (!this.refundForms.refundType) {
        return this.$message.warning('退款原因不能为空')
      }
      if (this.isDeductDeposit === -1) {
        return this.$message.warning('是否扣除保证金必须选择')
      }
      if (this.isDeductService === -1) {
        return this.$message.warning('是否扣除手续费必须选择')
      }
      if (this.returnAndRefundsType === -1) {
        return this.$message.warning('退款类型必须选择')
      }
      let strData = ''
      if (this.userRateData) {
        strData = this.userRateData.couponDesc + '<br/>'
            + this.userRateData.depositDesc + '<br/>'
            + this.userRateData.serviceMoneyDesc + '<br/>'
            + this.userRateData.incomePersonStr
      }
      const formDataArray = [
        {key: '申请退货退款类型', value: this.refundTypeText[this.refundForms.refundType]},
        {key: this.depositTitle, value: this.isDeductDeposit ? '扣除' : '不扣除'},
        {key: this.logisticsFeeTitle, value: this.refundForms.expressMoney},
        {key: this.extraFeeTitle, value: this.refundForms.certificateMoney},
        {key: '退款类型', value: this.returnAndRefundsType === 'goodsnotreceived' ? '仅退款' : '退货退款'},
        {key: '是否扣除手续费', value: this.isDeductService ? '扣除' : '不扣除'},
        {key: '处罚补偿信息', value: strData},
      ]
      this.refundForms.formDataArray = formDataArray
      this.openRefundSubmit()
    },

    async openRefundSubmit() {
      this.refundForms.refundImages = this.picList.map(item => item.url)
      this.refundForms.isDeductDeposit = this.isDeductDeposit
      this.refundForms.isDeductService = this.isDeductService
      this.refundForms.returnAndRefundsType = this.returnAndRefundsType
      let postData = JSON.parse(JSON.stringify(this.refundForms));
      postData.expressMoney = postData.expressMoney * 1
      postData.certificateMoney = postData.certificateMoney * 1
      const res = await this.axios.post(
        "/dq_admin/orderReturns/platformOpenRefund",
        postData,
      );
      
      if (res.status == "200") {
        const { message } = res;
        this.$message.success(message);
        // this.getData(this.params);
        this.showRefundModal = false;
        await this.getDetail(this.orderID);
        this.cancelRefund();
      }
    },

    openRefundModal(order) {
      this.refundForms.orderId = order.id;
      this.refundForms.status = order.status;
      this.refundForms.certificateMoney = 0;
      this.refundForms.expressMoney = 0;
      this.refundForms.isDeductDeposit = 0
      this.refundForms.isDeductService = 1
      this.showRefundModal = true;
    },

    cancelRefund() {
      this.refundForms = {};
      this.userRateData = {};
    },

    async getData(params) {
      this.loading = true;
      const res = await this.axios("/dq_admin/orderProduct/list", {
        params: params,
      });
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
      const found = this.columns.find(el => el.key === 'sendProductStatus')
      if (this.isSendProductTimeoutStatus) {
        if (!found) {
          this.columns.push({key: 'sendProductStatus',title: "即将发货超时", align: "center", dataIndex: 'remainingDeliveryTime' , width: 150})
        }
      } else {
        if (found) {
          this.columns = this.columns.filter(el => el.key !== 'sendProductStatus')
        }
      }
    },
    search() {
      this.params.pageNum = 1;
      this.pagination.current = 1
      this.getData(this.params);
    },

    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getData(this.params);
    },
    /** 改变选择延长收货时间 */
    handleChangeTag(id) {
      this.tagDayList.forEach(el => {
        el.selected = el.id === id;
      })
    },
    openModal(row) {
      tagDayList.forEach(el => {
        el.selected = false
      })
      this.tagDayList = tagDayList
      const { orderProductId } = row;
      this.orderID = orderProductId;
      this.getDetail(this.orderID);
      this.show = true;
    },
    async getDetail(id) {
      if (this.currentLogType === 'returnOrder') {
        id = this.orderID
      }
      const res = await this.axios("/dq_admin/orderProduct/getDetail", {
        params: { id },
      });
      if (res.status == 200) {
        const {
          orderProductInfoVo,
          orderLogisticsVo,
          orderLogVo,
          orderReturnVo,
          buyerDetailList,
          sellerDetailList,
          buyerDepositDetailList,
          sellerDepositDetailList,
          productSnapshot,
          gradeLogisticsVo,
          ratingInfoVo
        } = res.data;
        this.orderProductInfoVo = orderProductInfoVo;
        this.orderLogisticsVo = orderLogisticsVo;
        this.orderLogVo = orderLogVo;
        this.orderReturnVo = orderReturnVo;
        this.gradeLogisticsVo = gradeLogisticsVo
        this.buyerDetailList = buyerDetailList;
        this.sellerDetailList = sellerDetailList;
        this.buyerDepositDetailList = buyerDepositDetailList;
        this.sellerDepositDetailList = sellerDepositDetailList;
        this.productSnapshot = productSnapshot
        this.ratingInfoVo = ratingInfoVo
      }
    },
    async refund(orderReturnId, status) {
      if (!orderReturnId)
        return this.$message.warning("缺少该参数orderReturnId");
      const params = { orderReturnId, status };
      const res = await this.axios.post(
        "/dq_admin/orderReturns/cancelOrAgree",
        params
      );
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        await this.getDetail(this.orderID);
        await this.getData(this.params);
      }
    },
    async activeRefund(orderReturnId, orderId) {
      if (this.returnPayLoading) return this.$message.warning("请勿重复提交");
      this.returnPayLoading = true
      if (!orderReturnId && !orderId) return this.$message.warning("orderId/orderReturnId不能为空");
      const params = {
        orderReturnId: orderReturnId,
        orderId: orderId
      }
      this.returnPayLoading = false
      this.$loading.show()
      const res = await this.axios.post(
          "/dq_admin/orderReturns/bidOrderNotSendRefund",
          params
      );
      this.$loading.hide()
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        await this.getDetail(this.orderID);
        await this.getData(this.params);
      }
    },

    openModalSnapshot(orderId) {
      this.snapshotshow = true;
      this.orderId = orderId;
      this.getSnapshot(this.orderId);
    },

    async getSnapshot(orderId) {
      const res = await this.axios("/dq_admin/orderProduct/getOrderSnapshot", {
        params: { orderId },
      });
      if (res.status == 200) {
        const { productVersion } = res.data;
        this.productVersion = productVersion;
      }
    },

    async remark(orderId) {
      const params = { orderId, content: this.remarkText };
      const res = await this.axios.post("/dq_admin/orderLog/add", params);
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        this.remarkText = "";
        await this.getDetail(this.orderID);
      }
    },
    previewImg(list) {
      if (list && list.length > 0) {
        this.$previewImg({
          list: list.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },

    async bidOrderDelay(orderId) {
      if (!orderId) return this.$message.warning("orderId不存在");
      const found = this.tagDayList.find(el => el.selected)
      if (!found) return this.$message.warning('请先选择延长天数')
      const params = {
        daySize: found.id,
        orderId,
      }
      const res = await this.axios("/dq_admin/orderReturns/bidOrderDelay", {
        params: params,
      });
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        await this.getDetail(this.orderID);
        await this.getData(this.params);
      }
    },
    sortPrice(s) {
      this.params.priceSort = s;
      this.getData(this.params);
    },

    // 搜索创建日期筛选
    searchPickerChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.params.createStartTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          } else {
            this.params.createEndTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          }
        });
      } else {
        this.params.createStartTime = "";
        this.params.createEndTime = "";
      }
    },
    searchEndAuctionPickerChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.params.productEndStartTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          } else {
            this.params.productEndTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          }
        });
      } else {
        this.params.productEndStartTime = "";
        this.params.productEndTime = "";
      }
    },
    searchPayPickerChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.params.payStartTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          } else {
            this.params.payEndTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          }
        });
      } else {
        this.params.payStartTime = "";
        this.params.payEndTime = "";
      }
    },


  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
.modal-items {
  width: 100%;
  .title {
    position: relative;
    width: 30%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(215, 215, 215);
    font-weight: bold;
    .change-btn {
      position: absolute;
      right: -80%;
    }
  }
  .modal-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .basic-item {
      margin-right: 40px;
      margin-bottom: 10px;
    }
  }
  .modal-express {
    display: flex;
    flex-direction: column;
    .express {
      margin-bottom: 20px;
    }
    .order-log {
      margin-bottom: 20px;
    }
  }
}
.color-blue {
  color: #2f54eb !important;
}
.color-orange {
  color: orange !important;
}
.color-gary {
  color: grey !important;
}
.color-black {
  color: black !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.color-red {
  color: red !important;
}
.dis-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dis-flex-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-200 {
  margin-left: 200px !important;
}
.cursor-pointer {
  cursor: pointer;
}
::v-deep .ant-tag {
  padding: 6px !important;
  font-size: 14px !important;
}
</style>