var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: {
                size: "small",
                placeholder: "版别名称 或 ID",
                allowClear: ""
              },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.search($event)
                }
              },
              model: {
                value: _vm.searchParams.versionName,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "versionName", $$v)
                },
                expression: "searchParams.versionName"
              }
            }),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  size: "small",
                  "filter-option": _vm.untils.filterOption,
                  showSearch: "",
                  placeholder: "大币种",
                  allowClear: ""
                },
                on: { search: _vm.bigCoinSearch, change: _vm.selectBigCoin },
                model: {
                  value: _vm.searchParams.coinSId,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "coinSId", $$v)
                  },
                  expression: "searchParams.coinSId"
                }
              },
              _vm._l(_vm.searchBigCoins, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.sid } },
                  [_vm._v(_vm._s(item.coinKindName))]
                )
              }),
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  size: "small",
                  showSearch: "",
                  "filter-option": _vm.untils.filterOption,
                  placeholder: "小币种",
                  allowClear: ""
                },
                model: {
                  value: _vm.searchParams.coinItemId,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "coinItemId", $$v)
                  },
                  expression: "searchParams.coinItemId"
                }
              },
              _vm._l(_vm.searchLittleCoins, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.coinKindItemName))]
                )
              }),
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  size: "small",
                  showSearch: "",
                  "filter-option": _vm.untils.filterOption,
                  placeholder: "类别",
                  allowClear: ""
                },
                model: {
                  value: _vm.searchParams.categoryId,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "categoryId", $$v)
                  },
                  expression: "searchParams.categoryId"
                }
              },
              _vm._l(_vm.searchCategorys, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.coinCategoryName))]
                )
              }),
              1
            ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "a-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    return _vm.openModal({})
                  }
                }
              },
              [_vm._v("新增版别")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "table-content",
          staticStyle: { "padding-top": "20px" }
        },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record) {
                return record.id
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "taPianImage",
                fn: function(row) {
                  return _c("div", {}, [
                    row.coinKindVersionImage
                      ? _c("img", {
                          staticStyle: {
                            "max-width": "50px",
                            "max-height": "50px"
                          },
                          attrs: { src: row.coinKindVersionImage },
                          on: {
                            click: function($event) {
                              return _vm.handleShowBigImg(
                                [
                                  row.coinKindVersionImage,
                                  row.coinKindVersionBackImage
                                ],
                                0
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    row.coinKindVersionBackImage
                      ? _c("img", {
                          staticStyle: {
                            "max-width": "50px",
                            "max-height": "50px"
                          },
                          attrs: { src: row.coinKindVersionBackImage },
                          on: {
                            click: function($event) {
                              return _vm.handleShowBigImg(
                                [
                                  row.coinKindVersionImage,
                                  row.coinKindVersionBackImage
                                ],
                                1
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ])
                }
              },
              {
                key: "shiWuImage",
                fn: function(row) {
                  return _c("div", {}, [
                    row.front_img
                      ? _c("img", {
                          staticStyle: {
                            "max-width": "50px",
                            "max-height": "50px"
                          },
                          attrs: { src: row.front_img },
                          on: {
                            click: function($event) {
                              return _vm.handleShowBigImg(
                                [row.front_img, row.contrary_img],
                                0
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    row.contrary_img
                      ? _c("img", {
                          staticStyle: {
                            "max-width": "50px",
                            "max-height": "50px"
                          },
                          attrs: { src: row.contrary_img },
                          on: {
                            click: function($event) {
                              return _vm.handleShowBigImg(
                                [row.front_img, row.contrary_img],
                                1
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ])
                }
              },
              {
                key: "sizeWeight",
                fn: function(row) {
                  return _c("div", { staticClass: "size-weight" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          (row.versionSize || "") +
                            "/" +
                            (row.versionWeight || "")
                        ) +
                        " "
                    )
                  ])
                }
              },
              {
                key: "Rate",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    _vm._l(row.scoreList, function(item, index) {
                      return _c("span", { key: index }, [
                        _vm._v(
                          _vm._s(item.scoreName) +
                            _vm._s(index == row.scoreList.length - 1 ? "" : ",")
                        )
                      ])
                    }),
                    0
                  )
                }
              },
              {
                key: "coinKindVersionName",
                fn: function(row) {
                  return _c("div", {}, [
                    _c("div", [_vm._v(_vm._s(row.coinKindVersionName))]),
                    _c("div", [
                      _vm._v(_vm._s(row.isDuiban == 1 ? "(是)" : "(否)"))
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(row.forbidRotate == 1 ? "(不可旋)" : "(可旋)")
                      )
                    ])
                  ])
                }
              },
              {
                key: "tags",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _vm._l(row.tagList, function(tag, key) {
                        return _c(
                          "a-tag",
                          { key: key, attrs: { color: "blue" } },
                          [_vm._v(_vm._s(tag.coinTagName))]
                        )
                      }),
                      _c(
                        "a-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.addTagClick(row)
                            }
                          }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    2
                  )
                }
              },
              {
                key: "hqSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    row.quanhaiVersionId
                      ? _c("div", [
                          _c("div", [_vm._v(_vm._s(row.quanhaiVersionName))]),
                          _c(
                            "div",
                            { staticStyle: { "margin-top": "10px" } },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleChangeBinding(
                                        row,
                                        "change"
                                      )
                                    }
                                  }
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "a-popconfirm",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: {
                                    title: "确定解绑吗？",
                                    "ok-text": "Yes",
                                    "cancel-text": "No"
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.handleChangeBinding(
                                        row,
                                        "none"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { type: "danger", size: "small" }
                                    },
                                    [_vm._v("解绑")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _c(
                          "div",
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleChangeBinding(
                                      row,
                                      "change"
                                    )
                                  }
                                }
                              },
                              [_vm._v("绑定")]
                            )
                          ],
                          1
                        )
                  ])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      row.isSilver == 1
                        ? _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "Are you sure clear this cache?",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.clearCache(row.id)
                                }
                              }
                            },
                            [
                              _c(
                                "a",
                                { staticStyle: { "margin-right": "5px" } },
                                [_vm._v("清缓存")]
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure delete this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.innerTitle,
            "ok-text": "确认",
            "cancel-text": "取消",
            width: 700
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "大币种", prop: "coinKindSid" } },
                [
                  _c(
                    "a-select",
                    {
                      ref: "addse",
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      on: {
                        select: _vm.selectFn,
                        change: _vm.changeSelect,
                        search: _vm.searchData
                      },
                      model: {
                        value: _vm.forms.coinKindSid,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "coinKindSid", $$v)
                        },
                        expression: "forms.coinKindSid"
                      }
                    },
                    _vm._l(_vm.currency_big_list, function(item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.sid,
                          attrs: { title: item.coinCategoryId, value: item.sid }
                        },
                        [
                          _vm._v(_vm._s(item.coinKindName)),
                          item.dynastyName
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.dynastyName))
                              ])
                            : _vm._e(),
                          item.dynastyName
                            ? _c("span", [_vm._v(_vm._s(item.rulerName))])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "小币种", prop: "coinKindItemSid" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.forms.coinKindItemSid,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "coinKindItemSid", $$v)
                        },
                        expression: "forms.coinKindItemSid"
                      }
                    },
                    _vm._l(_vm.currency_small_list, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.sid, attrs: { value: item.sid } },
                        [_vm._v(_vm._s(item.coinKindItemName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "版别名称", prop: "coinKindVersionName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "版别名称" },
                    model: {
                      value: _vm.forms.coinKindVersionName,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "coinKindVersionName", $$v)
                      },
                      expression: "forms.coinKindVersionName"
                    }
                  })
                ],
                1
              ),
              _vm.editCoinId
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "版别特征", prop: "versionTag" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticClass: "w-300 ml-10",
                          attrs: {
                            dropdownMatchSelectWidth: false,
                            "filter-option": _vm.untils.filterOption,
                            dropdownMenuStyle: { "max-height": "350px" },
                            allowClear: "",
                            showSearch: "",
                            mode: "multiple",
                            placeholder: "选择版别特征"
                          },
                          model: {
                            value: _vm.versionNatureList,
                            callback: function($$v) {
                              _vm.versionNatureList = $$v
                            },
                            expression: "versionNatureList"
                          }
                        },
                        _vm._l(_vm.versionNatureListData, function(item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.natureName,
                              attrs: { value: item.natureName }
                            },
                            [_vm._v(_vm._s(item.natureName))]
                          )
                        }),
                        1
                      ),
                      _vm.editCoinId
                        ? _c(
                            "a-button",
                            {
                              staticClass: "ml-20",
                              attrs: { type: "primary" },
                              on: { click: _vm.handleAddVersionNatureType }
                            },
                            [_vm._v("新增特征")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "价格区间" } },
                [
                  _c(
                    "a-input-group",
                    { staticStyle: { width: "300px" }, attrs: { compact: "" } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "100px", "text-align": "center" },
                        attrs: { type: "number", placeholder: "最低价格" },
                        model: {
                          value: _vm.forms.versionMinPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "versionMinPrice", $$v)
                          },
                          expression: "forms.versionMinPrice"
                        }
                      }),
                      _c("a-input", {
                        staticStyle: {
                          width: "30px",
                          "border-left": "0",
                          "pointer-events": "none",
                          backgroundColor: "#fff"
                        },
                        attrs: { placeholder: "~", disabled: "" }
                      }),
                      _c("a-input", {
                        staticStyle: {
                          width: "100px",
                          "text-align": "center",
                          "border-left": "0"
                        },
                        attrs: { type: "number", placeholder: "最高价格" },
                        model: {
                          value: _vm.forms.versionMaxPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "versionMaxPrice", $$v)
                          },
                          expression: "forms.versionMaxPrice"
                        }
                      }),
                      _vm.forms.versionMinPrice || _vm.forms.versionMaxPrice
                        ? _c("a-button", {
                            attrs: { icon: "close-circle" },
                            on: { click: _vm.handleClearPriceRate }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "版别描述", prop: "versionDesc" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: "版别描述",
                      type: "textarea",
                      rows: 3
                    },
                    model: {
                      value: _vm.forms.versionDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "versionDesc", $$v)
                      },
                      expression: "forms.versionDesc"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "内部标记", prop: "versionDesc" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: "内部标记",
                      type: "textarea",
                      rows: 3
                    },
                    model: {
                      value: _vm.forms.internalMark,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "internalMark", $$v)
                      },
                      expression: "forms.internalMark"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标签" } },
                [
                  _vm._l(_vm.forms.tagList, function(_item, _index) {
                    return _c(
                      "a-tag",
                      {
                        key: _index,
                        attrs: { closable: "" },
                        on: {
                          close: function($event) {
                            return _vm.removeTag(_vm.forms.tagList, _index)
                          },
                          click: function($event) {
                            return _vm.confirmTag(_item, _index)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_item.coinTagName))]
                    )
                  }),
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.confirmTag({})
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                ],
                2
              ),
              _vm.showSet == true
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "PCGS编码", prop: "pcgsCode" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "PCGS编码" },
                        model: {
                          value: _vm.forms.pcgsCode,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "pcgsCode", $$v)
                          },
                          expression: "forms.pcgsCode"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showSet == true
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "评分" } },
                    _vm._l(_vm.editRanks, function(item, index) {
                      return _c(
                        "a-checkable-tag",
                        {
                          key: index,
                          attrs: { checked: item.checked },
                          on: {
                            change: function($event) {
                              return _vm.changeTag(index)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.scoreName) + " ")]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "排序" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "排序" },
                    model: {
                      value: _vm.forms.coinKindVersionSort,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "coinKindVersionSort", $$v)
                      },
                      expression: "forms.coinKindVersionSort"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "是否推荐" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        allowClear: "",
                        size: "small",
                        placeholder: "数据源"
                      },
                      on: { change: _vm.changeFormsRe },
                      model: {
                        value: _vm.forms.coinKindVersionRecommend,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "coinKindVersionRecommend", $$v)
                        },
                        expression: "forms.coinKindVersionRecommend"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("不推荐")
                      ]),
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("推荐")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.forms.coinKindVersionRecommend == 1
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "推荐排序" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "推荐排序" },
                        model: {
                          value: _vm.forms.recommendSort,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "recommendSort", $$v)
                          },
                          expression: "forms.recommendSort"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "拓片图 正面url" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "拓片图 正面url" },
                    model: {
                      value: _vm.forms.coinKindVersionImage,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "coinKindVersionImage", $$v)
                      },
                      expression: "forms.coinKindVersionImage"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "拓片图 反面url" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "拓片图 反面url" },
                    model: {
                      value: _vm.forms.coinKindVersionBackImage,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "coinKindVersionBackImage", $$v)
                      },
                      expression: "forms.coinKindVersionBackImage"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "拓片 正面图" } },
                [
                  _c("UploadImage", {
                    attrs: { list: _vm.VImages, maxCount: 1 },
                    on: { change: _vm.VImagesChange }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "拓片 反面图" } },
                [
                  _c("UploadImage", {
                    attrs: { list: _vm.VBImages, maxCount: 1 },
                    on: { change: _vm.VBImagesChange }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "实物正反图片" } },
                [
                  _c("UploadImage", {
                    attrs: { list: _vm.coverList, maxCount: 2 },
                    on: { change: _vm.coverListChange }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "版别级别" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        allowClear: "",
                        size: "small",
                        placeholder: "版别级别"
                      },
                      model: {
                        value: _vm.forms.versionGrade,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "versionGrade", $$v)
                        },
                        expression: "forms.versionGrade"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: "无" } }, [
                        _vm._v("无")
                      ]),
                      _c("a-select-option", { attrs: { value: "一级" } }, [
                        _vm._v("一级")
                      ]),
                      _c("a-select-option", { attrs: { value: "二级" } }, [
                        _vm._v("二级")
                      ]),
                      _c("a-select-option", { attrs: { value: "三级" } }, [
                        _vm._v("三级")
                      ]),
                      _c("a-select-option", { attrs: { value: "四级" } }, [
                        _vm._v("四级")
                      ]),
                      _c("a-select-option", { attrs: { value: "五级" } }, [
                        _vm._v("五级")
                      ]),
                      _c("a-select-option", { attrs: { value: "六级" } }, [
                        _vm._v("六级")
                      ]),
                      _c("a-select-option", { attrs: { value: "七级" } }, [
                        _vm._v("七级")
                      ]),
                      _c("a-select-option", { attrs: { value: "八级" } }, [
                        _vm._v("八级")
                      ]),
                      _c("a-select-option", { attrs: { value: "九级" } }, [
                        _vm._v("九级")
                      ]),
                      _c("a-select-option", { attrs: { value: "十级" } }, [
                        _vm._v("十级")
                      ]),
                      _c("a-select-option", { attrs: { value: "常见版" } }, [
                        _vm._v("常见版")
                      ]),
                      _c("a-select-option", { attrs: { value: "稀有版" } }, [
                        _vm._v("稀有版")
                      ]),
                      _c("a-select-option", { attrs: { value: "名誉版" } }, [
                        _vm._v("名誉版")
                      ]),
                      _c("a-select-option", { attrs: { value: "多见" } }, [
                        _vm._v("多见")
                      ]),
                      _c("a-select-option", { attrs: { value: "较多" } }, [
                        _vm._v("较多")
                      ]),
                      _c("a-select-option", { attrs: { value: "略少" } }, [
                        _vm._v("略少")
                      ]),
                      _c("a-select-option", { attrs: { value: "珍稀" } }, [
                        _vm._v("珍稀")
                      ]),
                      _c("a-select-option", { attrs: { value: "罕见" } }, [
                        _vm._v("罕见")
                      ]),
                      _c("a-select-option", { attrs: { value: "少见" } }, [
                        _vm._v("少见")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "版别尺寸" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "版别尺寸" },
                    model: {
                      value: _vm.forms.versionSize,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "versionSize", $$v)
                      },
                      expression: "forms.versionSize"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "版别重量" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "版别重量" },
                    model: {
                      value: _vm.forms.versionWeight,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "versionWeight", $$v)
                      },
                      expression: "forms.versionWeight"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "尺寸重量描述" } },
                [
                  _c("a-textarea", {
                    staticStyle: { width: "600px" },
                    attrs: {
                      type: "text",
                      "auto-size": { minRows: 3, maxRows: 6 },
                      placeholder: "尺寸重量描述"
                    },
                    model: {
                      value: _vm.forms.sizeWeightRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "sizeWeightRemark", $$v)
                      },
                      expression: "forms.sizeWeightRemark"
                    }
                  }),
                  _c("div", { staticClass: "color-red" }, [
                    _vm._v("tip：展示于图库详情页，全文案描述")
                  ])
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "泉谱书籍" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "泉谱书籍" },
                    model: {
                      value: _vm.forms.quanpuBook,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "quanpuBook", $$v)
                      },
                      expression: "forms.quanpuBook"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "书籍编号" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "书籍编号" },
                    model: {
                      value: _vm.forms.quanpuBookSerial,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "quanpuBookSerial", $$v)
                      },
                      expression: "forms.quanpuBookSerial"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "是否对版" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "radioGroup" },
                      model: {
                        value: _vm.forms.isDuiban,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "isDuiban", $$v)
                        },
                        expression: "forms.isDuiban"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("是")]),
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("否")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "是否旋转" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "radioGroup" },
                      model: {
                        value: _vm.forms.forbidRotate,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "forbidRotate", $$v)
                        },
                        expression: "forms.forbidRotate"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("是")]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("否")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "图库禁用该版" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "radioTuku" },
                      model: {
                        value: _vm.forms.forbidTuku,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "forbidTuku", $$v)
                        },
                        expression: "forms.forbidTuku"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("否")]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("是")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "成交价搜索类型：" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { allowClear: "", placeholder: "成交价搜索类型" },
                      model: {
                        value: _vm.forms.goodpriceSearchType,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "goodpriceSearchType", $$v)
                        },
                        expression: "forms.goodpriceSearchType"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("大币种 AND 版别名称 + 版别ID")
                      ]),
                      _c("a-select-option", { attrs: { value: 5 } }, [
                        _vm._v("大币种 AND 搜索词 + 版别ID")
                      ]),
                      _c("a-select-option", { attrs: { value: 10 } }, [
                        _vm._v("版别ID精确匹配")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.forms.goodpriceSearchType === 5
                ? _c(
                    "div",
                    _vm._l(_vm.customSearchWordList, function(item, index) {
                      return _c(
                        "a-form-model-item",
                        {
                          key: item.value,
                          attrs: {
                            label: "成交价搜索自定义名称--" + (index + 1) + "："
                          }
                        },
                        [
                          _c("a-input", {
                            staticClass: "w-300",
                            attrs: { placeholder: "成交价搜索自定义名称" },
                            model: {
                              value: _vm.customSearchWordList[index].text,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.customSearchWordList[index],
                                  "text",
                                  $$v
                                )
                              },
                              expression: "customSearchWordList[index].text"
                            }
                          }),
                          _vm.customSearchWordList.length - 1 === index
                            ? _c("a-button", {
                                staticClass: "ml-20",
                                attrs: { type: "primary", icon: "plus" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleAddCustomSearchWordItem(
                                      index
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.customSearchWordList.length > 1
                            ? _c("a-button", {
                                staticClass: "ml-20",
                                attrs: { type: "danger", icon: "minus" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDeleteCustomSearchWordItem(
                                      index
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "标签",
            "ok-text": "确认",
            "cancel-text": "取消",
            width: 534
          },
          on: {
            cancel: function($event) {
              _vm.tagForms = {}
            },
            ok: _vm.addTag
          },
          model: {
            value: _vm.tagVisible,
            callback: function($$v) {
              _vm.tagVisible = $$v
            },
            expression: "tagVisible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "tagforms",
                attrs: { model: _vm.tagForms, rules: _vm.tagRules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "coinTagName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "名称" },
                    model: {
                      value: _vm.tagForms.coinTagName,
                      callback: function($$v) {
                        _vm.$set(_vm.tagForms, "coinTagName", $$v)
                      },
                      expression: "tagForms.coinTagName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标签分类", prop: "coinTagCategory" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "标签分类" },
                      model: {
                        value: _vm.tagForms.coinTagCategory,
                        callback: function($$v) {
                          _vm.$set(_vm.tagForms, "coinTagCategory", $$v)
                        },
                        expression: "tagForms.coinTagCategory"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("别名标签")
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v("钱币性质标签")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "评分",
            "ok-text": "确认",
            "cancel-text": "取消",
            width: 534
          },
          on: { ok: _vm.addRate, cancel: _vm.rateCancel },
          model: {
            value: _vm.rateVisible,
            callback: function($$v) {
              _vm.rateVisible = $$v
            },
            expression: "rateVisible"
          }
        },
        [
          _c("a-input", {
            model: {
              value: _vm.rateInner,
              callback: function($$v) {
                _vm.rateInner = $$v
              },
              expression: "rateInner"
            }
          })
        ],
        1
      ),
      _c("BottleAddTag", { ref: "addTag", on: { success: _vm.addTagSuccess } }),
      _c("BindingVersionPopup", {
        ref: "bind-version",
        on: { success: _vm.getData }
      }),
      _c("AddVersionNatureTypePopup", {
        ref: "addVersionNatureTypePopupEl",
        on: { change: _vm.getVersionNatureListEvent }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }