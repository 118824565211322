<template>
  <a-upload
    :key="imgKey"
    :action="ip + '/file/upload'"
    list-type="picture-card"
    :file-list="list"
    @preview="handlePreview()"
    @change="handleChange"
  >
    <div v-if="list.length < maxCount">
      <a-icon type="plus" />
      <div class="ant-upload-text">上传图片</div>
    </div>
  </a-upload>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    // 图片列表
    list: {
      default: [],
    },
    // 最大上传数量
    maxCount:{
      default: 1000
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["ip"]),
    imgKey() {
      return Math.random();
    },
  },
  methods: {
    // 图片改变
    handleChange({ fileList }) {
      if (fileList.length > this.maxCount) {
        return this.$message.warning(`只能选择${this.maxCount}张`);
      }
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.$emit("change", fileList)
    },
    async handlePreview() {
      let urls = this.list.map((el) => {
        return {
          img_url: el.url,
        };
      });
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style>
</style>