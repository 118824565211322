<template>
  <div class="content-box">
    <a-modal
        :width="700"
        centered
        v-model="showModal"
        title="修改物流信息"
        @ok='handleConfirmChangeMessage'
        @cancel='cancel'
    >
      <div style="display: flex;justify-content: flex-start;align-items: center">
        <div class="flex-start-center">
          <span>收件人：</span>
          <a-input
              :disabled="orderType === 'rateService'"
              allowClear
              v-model="params.consigneeRealname"
              style="width:150px"
              placeholder='输入收件人姓名'
          ></a-input>
        </div>
        <div class="flex-start-center ml-20">
          <span>收件人电话：</span>
          <a-input
              :disabled="orderType === 'rateService'"
              allowClear
              v-model="params.consigneeTelphone"
              style="width:150px"
              placeholder='输入收件人电话'
          ></a-input>
        </div>
      </div>
      <div style="display: flex;justify-content: flex-start;align-items: center">
        <span>详情地址：</span>
        <a-input
            allowClear
            :disabled="true"
            v-model="params.consigneeAddress"
            style="width:430px;margin-top: 10px;"
            placeholder='输入详情地址'
        ></a-input>
        <a-button
            v-if="orderType !== 'rateService'"
            class="ml-10 mt-10"
            type="danger"
            size="small"
            @click="handleShowEditorAddressInfo">编辑地址</a-button>
      </div>
      <div style="display: flex;margin-top: 10px;" v-if="currentData.expressNo">
        <span>物流单号：</span>
        <a-input
            allowClear
            v-model="params.expressNo"
            style="width:230px"
            placeholder='输入物流单号'
        ></a-input>
      </div>
      <div style="display: flex;justify-content: flex-start;align-items: center" v-if="currentData.logisticsId">
        <span>物流公司：</span>
        <a-select
            allowClear
            v-model="params.logisticsId"
            style="width:300px;margin-top: 10px;"
            placeholder='请选择物流公司'
        >
          <a-select-option
              v-for="(item,key) of logisticsCompanyList"
              :value='item.id'
              :key="key"
          >{{item.companyName}}</a-select-option>
        </a-select>
      </div>
    </a-modal>
    <ChangeAddressInfo ref="changeAddressInfoEl" @edit="handleEditorAddressInfo($event)"/>
  </div>
</template>

<script>
import {updateBaseObjectWithComplex} from "@/untils/otherEvent"
import ChangeAddressInfo from "@/components/ChangeAddressInfo/index.vue"

export default {
  components: {ChangeAddressInfo},
  data() {
    return {
      showModal: false,
      orderNo: '',
      orderType: '',
      currentData: '',
      params: {
        consigneeTelphone: undefined,
        consigneeRealname: undefined,
        logisticsOrder: undefined,
        consigneeAddress: undefined,
        expressNo: undefined,
        logisticsId: undefined,
      },
      logisticsCompanyList: [],
      orderTypeApi: {
        order: '/dq_admin/orderProduct/modifySenderLogistics',
        returnOrder: '/dq_admin/orderProduct/modifyRefundLogistics',
        rateService: '/dq_admin/orderProduct/modifyRatingLogistics'
      },
    }
  },
  methods: {
    /** 展示修改地址弹窗 */
    handleShowEditorAddressInfo() {
      this.$refs.changeAddressInfoEl.show()
    },
    /** 地址修改成功 */
    handleEditorAddressInfo(addressInfo) {
      this.params.consigneeAddress = addressInfo
    },
    /** 确定修改 */
    async handleConfirmChangeMessage() {
      delete this.params.id
      const temp = {
        orderId: this.orderNo,
        orderReturnId: this.orderNo
      }
      if (['order', 'rateService'].includes(this.orderType)) {
        delete temp.orderReturnId
      } else {
        delete temp.orderId
      }
      delete this.params.logisticsName;
      const res = await this.axios.post(this.orderTypeApi[this.orderType], {
        ...temp,
        ...this.params
      })
      if (res.status !== '200') return
      this.cancel()
      this.$message.success('修改成功')
      this.$emit('change', this.orderNo)
    },
    /** 显示 */
    async show(data, orderNo, type) {
      this.currentData = data
      this.params = updateBaseObjectWithComplex(this.params, data)
      if (!this.params.logisticsId) {
        this.params.logisticsId = undefined
      }
      this.orderNo = orderNo
      this.orderType = type
      await this.getLogisticsCompany()
      this.showModal = true
    },
    /** 关闭 */
    cancel() {
      this.currentData = ''
      this.params = {
        consigneeTelphone: undefined,
        consigneeRealname: undefined,
        logisticsOrder: undefined,
        consigneeAddress: undefined,
        expressNo: undefined,
        logisticsId: undefined,
      }
      this.logisticsCompanyList = []
      this.showModal = false
    },
    /** 获取物流公司信息 */
    async getLogisticsCompany() {
      const res = await this.axios('/dq_admin/logistics/list')
      if (res.status !== '200') return
      this.logisticsCompanyList = res.data
    },
  }
}
</script>

<style lang="scss" scoped>
.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.content-box {
  width: 600px;
}
.take-box{width:100%;}
.ml-20 {
  margin-left: 20px !important;
}
</style>